import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image'

class Header extends Component {
	render() {

		return (
			<Fragment>
            <header>

                <Container>
                    <Row>
                        <Col xs={6} md={4} lg={4} className="logo">
                            <a href="/" alt="AutoCom Romania" title="AutoCom Romania"><Image className="logo" alt="" src="logo.webp"/></a>
                        </Col>
                        <Col xs={6} md={8} lg={8} className="menu">
                            {['md'].map((expand) => (
                            <Navbar key={expand} expand={expand}>
                                <Container fluid>
                                <Navbar.Brand href="#"></Navbar.Brand>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        
                                    </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <Nav.Link title="Acasă" href="/">Acasă</Nav.Link>
                                        <Nav.Link title="Despre" href="#despre">Despre </Nav.Link>
                                        <Nav.Link title="Caracteristici" href="#caracteristici">Caracteristici </Nav.Link>
                                        <Nav.Link title="Avantaje" href="#avantaje">Avantaje </Nav.Link>
                                        <Nav.Link title="Contact" href="#contact">Contact</Nav.Link>
                                        <Nav.Link title="Comandă acum" className='buy_now' href="https://elevatorauto.ro/produs/sw4000ag/">Comandă</Nav.Link>
                                    </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                </Container>
                            </Navbar>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </header>
			</Fragment>
		)
	}

}

export default Header