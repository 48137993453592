import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Contact extends Component {

    constructor(){
		super();
		this.state={
            company:"",
            address:"",
            google_map:"",
            fiscal_code:"",
            trade_register:"",
            mobile:"",
            phone:"",
            email:"",
            website:"",
            work_time:"",
            copyright:"",
            facebook_url:"",
            instagram_url:"",
            twitter_url:"",
            linkedin_url:"",
            pinterest_url:"",
            tiktok_url:"",
		}
   }

   componentDidMount() {
    RestClient.GetRequest(AppUrl.Settings).then(result => {
        if (result && Array.isArray(result) && result.length > 0) {
            // Dacă result este definit, este un array și conține cel puțin un element
            this.setState({
                company: result[0]['company'],
                address: result[0]['address'],
                google_map: result[0]['google_map'],
                fiscal_code: result[0]['fiscal_code'],
                trade_register: result[0]['trade_register'],
                mobile: result[0]['mobile'],
                phone: result[0]['phone'],
                email: result[0]['email'],
                website: result[0]['website'],
                work_time: result[0]['work_time'],
                copyright: result[0]['copyright'],
                facebook_url: result[0]['facebook_url'],
                instagram_url: result[0]['instagram_url'],
                twitter_url: result[0]['twitter_url'],
                linkedin_url: result[0]['linkedin_url'],
                pinterest_url: result[0]['pinterest_url'],
                tiktok_url: result[0]['tiktok_url'],
            });
        } else {
            // Manevrare caz în care result este null sau nu conține date valide
            console.error("API call returned null or invalid data.");
            // Setarea unor valori implicite sau gestionarea erorii
        }
    }).catch(error => {
        // Gestionarea erorilor de la apelul API
        console.error("API call failed: ", error);
    });
}

	render() {
		return (
			<Fragment>
            <section id="contact">
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <h6>{this.state.company}</h6>
                            <p>{this.state.address}</p>
                            <p><strong>Cod fiscal:</strong> {this.state.fiscal_code} / <strong>Reg. Com.:</strong> {this.state.trade_register}</p>
                            <p><strong>Telefon:</strong> {this.state.phone}</p>
                            <p><strong>Mobil:</strong> {this.state.mobile}</p>
                            <p><strong>Email:</strong> {this.state.email}</p>
                            <div className='social_links'>
                                <p><strong>Suntem prezenti pe: </strong></p>
                                <ul>
                                    <li><a title="Facebook" target="_blank" rel="noreferrer" href={this.state.facebook_url}> <i className="fa-brands fa-facebook"></i> </a></li>
                                    <li><a title="Instagram" target="_blank" rel="noreferrer" href={this.state.instagram_url}> <i className="fa-brands fa-instagram"></i> </a></li>
                                    <li><a title="Twitter" target="_blank" rel="noreferrer" href={this.state.twitter_url}> <i className="fa-brands fa-twitter"></i> </a></li>
                                    <li><a title="Linkedin" target="_blank" rel="noreferrer" href={this.state.linkedin_url}> <i className="fa-brands fa-linkedin"></i> </a></li>
                                    <li><a title="Pinterest" target="_blank" rel="noreferrer" href={this.state.pinterest_url}> <i className="fa-brands fa-pinterest"></i> </a></li>
                                    <li><a title="TikTok" target="_blank" rel="noreferrer" href={this.state.tiktok_url}> <i className="fa-brands fa-tiktok"></i> </a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.google_map }} />
                        </Col>
                    </Row>
                </Container>
            </section>
			</Fragment>
		)
	}

}

export default Contact