import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Caracteristici extends Component {

    constructor(){
        super();
        this.state={
        block_title:"",
        block_subtitle:"",
        block_content:"",
        block_image:"",
        block_button:"",
        block_url:"",
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Block).then(result=>{
            const blockData = result.find(block => block.id === 15);
            if(blockData) {
                this.setState({
                    block_title: blockData.block_title,
                    block_subtitle: blockData.block_subtitle,
                    block_content: blockData.block_content,
                    block_image: blockData.block_image,
                    block_button: blockData.block_button,
                    block_url: blockData.block_url,
                });
            }
        }) 
    }
    

	render() {
		return (
			<Fragment>
            <section id="caracteristici">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <p><span>{this.state.block_subtitle}</span></p>
                            <h3>{this.state.block_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: this.state.block_content }} />
                        </Col>
                    </Row>
                </Container>
            </section>
			</Fragment>
		)
	}

}

export default Caracteristici