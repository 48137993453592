import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class Featured extends Component {

  constructor(){
      super();
      this.state={
        featuredData:[] 
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Featured).then(result=>{
      // Sortare în funcție de featured_order
      const sortedData = result.sort((a, b) => a.featured_order - b.featured_order);
      this.setState({featuredData: sortedData});
    }) 
  }
  

  render() {

    const FeaturedList = this.state.featuredData;
    const FeaturedView = FeaturedList.map((FeaturedList, index)=>{

    return <Col xs={12} md={3} lg={3} className='featured_block' key={index}>
      <p><i className={FeaturedList.featured_icon}></i></p>
      <p><span>{FeaturedList.featured_title}</span></p>
      {Parser(FeaturedList.featured_content)}
    </Col>  

    })

    return (
    <Fragment>
      <Container>
        <Row>
          {FeaturedView}
        </Row>
      </Container>
      <hr className='divider'/>
    </Fragment>
    )
  }

}

export default Featured