import React, { Component, Fragment } from 'react'

// Import Swiper styles
import 'swiper/css';

import Hero from '../components/Hero'
import Featured from '../components/Featured'
import Despre from '../components/Despre'
import Caracteristici from '../components/Caracteristici';
import Avantaje from '../components/Avantaje';
import Cta from '../components/Cta';
import Contact from '../components/Contact';

export class Home extends Component {

  render() {
    return (
      <Fragment>

        <Hero />
        <Featured />
        <Despre />
        <Caracteristici />
        <Avantaje />
        <Cta />
        <Contact />

      </Fragment>
    )
  }
}

export default Home