class AppUrl {
    static BaseURL = 'https://elevatorauto.ro/api';
    static StaticURL = 'https://elevatorauto.ro';
    
    static Settings = this.BaseURL + '/settings'; 
    static Block = this.BaseURL + '/block';
    static Featured = this.BaseURL + '/featured';
    static Cta = this.BaseURL + '/cta';
}

export default AppUrl;
