import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './common/Header';
import Footer from './common/Footer';

import Home from './pages/Home';

function App() {
  return (
    <div>
      <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{ background: "#3d443d" }}
          buttonStyle={{  background: "#cc011e", color: "#fff", border: "1px solid #fff", fontSize: "1rem" }}
          expires={150}
        >
          Folosim cookie-uri pentru a vă asigura o experiență mai buna pe website-ul nostru. Prin continuarea navigării, vă dați acordul. Vezi <a title="Politica de utilizare cookie" href="/cookies">politica cookies</a> 
        
        </CookieConsent>

      <Footer />
    </div>
  );
}

export default App;
